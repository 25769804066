import { Flex, Text, useColorMode } from "@chakra-ui/react";
import React from "react";
import Logo from "@/src/components/Logo";
import CustomLink from "@/src/components/CustomLink";

const AuthCard = ({ children, title, isLogin, isRegister, ...props }) => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      mt={{ base: 5, "2xl": 12 }}
      mb={{ base: 10, "2xl": 8 }}
      w={"full"}
      boxShadow={"md"}
      maxW={{ base: "full", sm: "530px", md: "540px" }}
      minH={"640px"}
      borderRadius={24}
      direction={"column"}
      py={{ base: "44px", "2xl": "44px" }}
      px={{ base: "44px", "2xl": "44px" }}
      bg={colorMode === "light" ? "white" : "#014d40"}
      color={"black"}
      align={"flex-start"}
      {...props}
    >
      <Text
        color={colorMode === "light" ? "inherit" : "white"}
        fontWeight={"400"}
        fontSize={"20px"}
        lineHeight={"39px"}
      >
        {title}
      </Text>
      <CustomLink to={"/"} paddingBottom={"30px"}>
        <Logo width={"215px"} height={"41px"} mb={1} />
      </CustomLink>
      {children}
    </Flex>
  );
};

export default AuthCard;
