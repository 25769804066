import React from 'react';
import {Flex, Heading, Text} from "@chakra-ui/react";

const AuthContent = ({children, title, sub_title, desc, ...props}) => {
	return (
		<Flex direction={{base: "column", lg: "row"}} h={"80%"} w={"full"} justify={"space-between"}
			  align={"flex-start"} {...props}>
			<Flex direction={"column"} color={"white"} justify={"center"} mt={{base: 8, lg: 48}} gap={6}
				  align={"flex-start"} flex={"0 1 50%"} mr={{lg: 36}}>
				<Heading as={"h2"} fontSize={{base: "24px", md: "34px"}} fontWeight={600}
						 lineHeight={{base: "18px", md: "41px"}}>{title}</Heading>
				<Text as={"h3"} fontSize={{base: "16px", md: "24px"}} fontWeight={400}
					  lineHeight={{base: "22px", md: "36px"}}>{sub_title}</Text>
				<Text as={"p"} display={{base: "none", md: "block"}} fontSize={{base: "12px", md: "16px"}}>{desc}</Text>
			</Flex>
			<Flex flex={"1 1 auto"} w={"full"}>
				{children}
			</Flex>
		</Flex>
	);
};

export default AuthContent;