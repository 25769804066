import React from "react";
import { Image, Stack, useColorMode } from "@chakra-ui/react";

const AuthShell = ({ children, ...props }) => {
  const { colorMode } = useColorMode();
  return (
    <Stack
      w={"full"}
      bg={colorMode === "light" ? "brand.500" : "#021b17"}
      overflow={"auto"}
      {...props}
    >
      {/*<Flex position={"absolute"} bottom={0} left={{base: 0, lg:8}}>*/}
      {/*	{<Image aria-label={"login pattern"} w={"full"} src={"/login_pattern.svg"}/>}*/}
      {/*</Flex>*/}

      {/* Ce flex en dessou est le modification apportee au premier flex */}

      <Stack
        w={"100vw"}
        h={"100vh"}
        position={"relative"}
        py={{ base: 4, sm: "121px", "2xl": "15px" }}
        px={{ base: 2, sm: 16, "2xl": 16, "3xl": 24, "5xl": "156px" }}
      >
        <Stack w={"full"} zIndex={1}>
          {children}
        </Stack>

        <Image
          aria-label={"login pattern"}
          position={"fixed"}
          bottom={{base: "-50%", "3xl": "0"}}
          left={{ base: 0, "2xl": "-60.42px" }}
          maxW={"583px"}
          maxH={"461px"}
          src={"/login_pattern.svg"}
        />
      </Stack>
    </Stack>
  );
};

export default AuthShell;
