
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import AuthContent from "@/src/components/AuthContent";
import AuthCard from "@/src/components/AuthCard";
import {Button, Heading, Stack, Text,useColorMode} from "@chakra-ui/react";
import AuthShell from "@/src/layouts/AuthShell";
import React from "react";
import {useRouter} from "next/router";

const NotFound = () => {
    const router = useRouter()
    const { colorMode } = useColorMode();
    return (
        <AuthShell>
            <AuthContent title={"Page not found"} sub_title={"Watchman, the best vulnerabilities monitoring system"}
                         desc={"We help you keep under control your business secure by keeping an eye on your information system. We monitor your information system for you, and we make you aware of any security weaknesses."}>
                <AuthCard>
                    <Heading size={"md"} mb={8} mt={4} color={colorMode === "light" ? "black" : "white"}>{"404 - Looks like you're lost"}</Heading>
                    <Stack
                        spacing={12}
                    >
                        <Text w={"full"} as={"p"} fontSize={"16px"} textAlign={"left"} color={colorMode === "light" ? "black" : "white"} fontWeight={500}>
                            Maybe this page used to exist or you just spelled something
                            wrong.
                            <br/>
                            <br/>
                            Chances are you spelled something wrong, so can you double
                            check the url?
                        </Text>
                        <Button
                            w={"full"}
                            fontWeight={500}
                            type={"button"}
                            onClick={() => router.back()}
                            colorScheme={"brand"}
                        >
                            Go back
                        </Button>
                    </Stack>
                </AuthCard>
            </AuthContent>
        </AuthShell>
    );
};

export default NotFound;

    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  